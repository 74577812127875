<template>
    <v-row>
    <v-col cols="12" sm="2">
	<v-text-field
        hide-details dense outlined
        :label="label + ' [KW]'"
        :ref="name + 'kw'"
        v-model="valuekw"
        :placeholder="label + ' [KW]'"
        :reverse="true"
        @input="hasChanged($event)"
        @keypress="isAllowed($event)"
        autocomplete="new-password"
    >
        <template v-slot:append-outer>
            <v-icon>mdi-slash-forward</v-icon>
        </template>
    </v-text-field>
    </v-col>
    <v-col cols="12" sm="2">
	<v-text-field
        hide-details dense outlined
        :label="label + ' [J]'"
        :ref="name + 'jr'"
        v-model="valuejr"
        :placeholder="label + ' [J]'"
        :reverse="true"
        @input="hasChanged($event)"
        @keypress="isAllowed($event)"
        autocomplete="new-password"
    ></v-text-field>
    </v-col>
    <v-col cols="12" sm="2">
	<v-text-field
        hide-details dense outlined
        :label="label"
        :ref="name"
        v-model="newValue"
        :placeholder="label"
        :reverse="true"
        readonly
    ></v-text-field>
    </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'KWInput',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        reverse: { 'default': false, 'number': true, 'date': false, 'kw': true, 'decimal': true, 'euro': true, 'proz': true },
        valuekw: '',
        valuejr: '',
        newValue: '',
    }),
    mounted() {
        if(this.value && this.value.search("/") >= 0) {
            let res = this.value.split("/");
            if(res.length > 1) {
                this.valuekw = res[0]
                this.valuejr = res[1]
            }
        }
        this.newValue = this.valuekw + '/' + this.valuejr
        /*if(this.reverse[this.format]) {
            let swap = this.prefix
            this.prefix = this.suffix
            this.suffix = swap
        }
        console.log('NumberInput[' + this.name + ']:', this.format, this.reverse[this.format], this.prefix[this.format], this.suffix[this.format])*/
    },
    methods: {
        isAllowed(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[0-9]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        },
        hasChanged(e) {
            this.newValue = this.valuekw + '/' + this.valuejr
            //this.$emit('changed', this.newValue)
        }
    },
}
</script>